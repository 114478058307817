/** @jsxImportSource @emotion/react */
import { connect } from 'react-redux';
import InputBaseData from './InputBaseData';
import InputElement from './InputElement';
import InputRentalPerson from './InputRentalPerson';
import DebitorStatement from "./DebitorStatement";
import { selectSelected } from './redux/selectors';
import {
  GRAPHQL_DEBITOR_STATEMENT,
  NEW_PRIMARY_RENTAL_PERSON,
  OLD_PRIMARY_RENTAL_PERSON
} from "../constants";

const style = {
  content: {
    overflowY: 'auto',
    scrollBehavior: 'smooth',
    overflowScrolling: 'touch',
    WebkitOverflowScrolling: 'touch',
    // TODO: add also for firefox
    '::WebkitScrollbar': {
      width: '0px',
      height: '0px',
      background: 'transparent',
    },
    maxHeight: '100%',
  },
};

export const InputAreaAcceptanceProtocol = ({ selected }) => {
  if (!selected) {
    return false;
  }

  const id = selected.element && selected.element.id;
  const type = selected.type;

  if (!id || !type) {
    return false;
  }

  const renderContent = () => {
    switch (type) {
      case 'basedata':
        switch (id) {
          case OLD_PRIMARY_RENTAL_PERSON:
          case NEW_PRIMARY_RENTAL_PERSON:
            return <InputRentalPerson key={id} />;
          case `${OLD_PRIMARY_RENTAL_PERSON}/${GRAPHQL_DEBITOR_STATEMENT}`:
            return <DebitorStatement key={id} />;
          default:
            return <InputBaseData key={id} />;
        }
      case 'component':
        return <InputElement key={id} />;
      default:
        return JSON.stringify(selected);
    }
  };

  return <div css={style.content}>{renderContent()}</div>;
};

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    selected: selectSelected(state),
  };
}

export default connect(mapStateToProps)(InputAreaAcceptanceProtocol);
