/** @jsxImportSource @emotion/react */
import { connect } from "react-redux";
import { Component } from "react";
import { bindActionCreators } from "redux";
import * as actions from './redux/actions';
import * as commonActions from '../../common/redux/actions';
import { colors } from "../../../common/theme/colors";
import {
  selectDebitorStatementSuccess, 
  selectIsDebitorStatementLoading
} from "../../common/redux/selectors";
import { selectDebitorStatement } from "../redux/selectors";
import TableComponent from "./TableComponent";

const styles = {
  container: {
    display: 'grid',
    height: '100%',
  },
  section: {
    backgroundColor: `${colors.lighterGrey}`,
    alignItems: 'center',
    fontWeight: 'bold',
    padding: '1.5em 1.0em',
  },
  content: {
    padding: '1.5em',
    display: 'grid',
    gridAutoFlow: 'row',
    gridRowGap: '24px',
  },
};

export class DebitorStatement extends Component {
  render() {
    const { 
      isDebitorStatementLoading,
      isDebitorStatementSuccess,
      debitorStatement
    } = this.props;

    // Ensure columns and rows are not mutated
    const columns = debitorStatement?.headers?.map((header) => ({
  title: header,
  accessor: header,
  key: header,
})) ?? [];

const rows = debitorStatement?.rows
  ?.map((row, index) => {
    if (row.length === (debitorStatement.headers?.length ?? 0)) {
      const rowData = row.reduce((acc, value, i) => {
        acc[debitorStatement.headers[i]] = value;
        return acc;
      }, {});
      return { key: index, ...rowData };
    }
    return null;
  })
  .filter(Boolean) ?? [];

    return (
      <div css={styles.container}>
        <div css={styles.content}>
          <TableComponent
            isLoading={isDebitorStatementLoading}
            isSuccess={isDebitorStatementSuccess}
            columns={columns}
            data={rows}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isDebitorStatementLoading: selectIsDebitorStatementLoading(state),
    isDebitorStatementSuccess: selectDebitorStatementSuccess(state),
    debitorStatement: selectDebitorStatement(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions, ...commonActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DebitorStatement);
