// Initial state is the place you define all initial values for the Redux store of the feature.
// In the 'standard' way, initialState is defined in reducers: http://redux.js.org/docs/basics/Reducers.html
// But when application grows, there will be multiple reducers files, it's not intuitive what data is managed by the whole store.
// So Rekit extracts the initial state definition into a separate module so that you can have
// a quick view about what data is used for the feature, at any time.

// NOTE: initialState constant is necessary so that Rekit could auto add initial state when creating async actions.

import { LOGIN_WORKFLOW_DEFAULT } from "./loginWorkflowConstants";

const initialState = {
  language: getBrowserLanguage(),
  languages: ['de', 'fr', 'it'],
  version: 'dev',
  isOnline: navigator.onLine,
  username: '',
  client: '',
  locales: null,
  securityDepotTypes: null,
  clientConfig: null,
  protocols: [],
  leavingAgreements: [],
  queues: {},
  sessionId: null,
  offlineProtocols: [],
  errorSynchronizationProtocols: [],
  pdfs: {},
  keycloak: null,
  keycloakConfig: null,
  loginInfo: { customers: [] },
  currentSigner: null,
  loginWorkflow: LOGIN_WORKFLOW_DEFAULT,
  authInfo: {
    accessToken: null,
    refreshToken: null,
    idToken: null,
    expiresAt: null,
    isLoggedIn: false,
    isSsoLogin: false,
    isTokenExpired: false
  },
  ui: {
    busy: {
      config: false,
      login: false,
      getProtocols: false,
      deleteProtocol: false,
      queue: false,
      getPdfsFromProtocol: false,
      markAsOffline: {},
      syncOffline: {},
      isTenancyAgreementLoading: false,
      isDebitorStatementLoading: false,
    },
    modal: {
      notImplemented: false,
      confirmation: false,
      information: false,
      login: false,
    },

    confirmation: {
      title: null,
      message: null,
    },

    information: {
      title: null,
      message: null,
    },
  },
};

export default initialState;

function getBrowserLanguage() {
  const browserLanguage = navigator.language || navigator.userLanguage;

  switch (browserLanguage) {
    case 'it-IT':
    case 'it-CH':
      return 'it';
    case 'fr-FR':
    case 'fr-CH':
      return 'fr';
    case 'en-US':
    case 'en-UK':
      return 'en';
    case 'de-DE':
    case 'de-CH':
    default:
      return 'de';
  }
}
