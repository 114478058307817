import { call, put, takeLatest, select } from 'redux-saga/effects';
import produce from "immer";
import { get } from "../services/api";
import { i18n } from "../../../common/i18n-loader";
import {
  COMMON_GET_DEBITOR_STATEMENT,
  COMMON_GET_DEBITOR_STATEMENT_SUCCESS,
  COMMON_GET_DEBITOR_STATEMENT_ERROR,
  COMMON_GET_DEBITOR_STATEMENT_INITIATE,
  COMMON_NOTIFY_TENANCY_AGREEMENT_ERROR,
} from "./constants";
import {
  selectIsTenantsErased,
} from "../../acceptance-protocol/redux/selectors";
import { selectDebitorStatementEligibility, selectIsTokenExpired} from "./selectors";

export function commonGetDebitorStatement(payload) {
  return {
    type: COMMON_GET_DEBITOR_STATEMENT,
    payload: payload,
  };
}

export function* doGetDebitorStatement({ payload }) {
  try {
    const isTenantsErased = yield select(selectIsTenantsErased(payload));

    const isAllTenantsErased = Object.values(isTenantsErased).reduce(
      (result, currentValue) => result || !currentValue,
      false,
    );

    const permission = yield select(selectDebitorStatementEligibility(payload));
    if (isAllTenantsErased && permission) {
      yield put({
        type: COMMON_GET_DEBITOR_STATEMENT_INITIATE,
        payload
      });

      const debitorStatement = yield call(get, `additionalfeatures/debitorstatement/${payload}`);

      yield put({
        type: COMMON_GET_DEBITOR_STATEMENT_SUCCESS,
        payload: { protocolId: payload, debitorStatement },
      });
    }
  } catch (error) {
    const isTokenExpired = yield select(selectIsTokenExpired);

    yield put({
      type: COMMON_GET_DEBITOR_STATEMENT_ERROR,
      payload: { protocolId: payload },
    });

    if (!isTokenExpired) {
      yield put({
        type: COMMON_NOTIFY_TENANCY_AGREEMENT_ERROR,
        payload: i18n._('MESSAGES.TENANCY-AGREEMENT-ERROR'),
      });
    }
  }
}

export function* switchGetDebitorStatement() {
  yield takeLatest(COMMON_GET_DEBITOR_STATEMENT, doGetDebitorStatement);
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMMON_GET_DEBITOR_STATEMENT_INITIATE: {
        draft.ui.busy.isDebitorStatementLoading = true;
        const selectedProtocol = draft.protocols.find(
          (protocol) => protocol.id === action.payload
        );
        if(selectedProtocol) {
          selectedProtocol.debitorStatement = {};
        }
        break;
      }
      case COMMON_GET_DEBITOR_STATEMENT_SUCCESS: {
        const selectedProtocol = draft.protocols.find(
          (protocol) => protocol.id === action.payload.protocolId,
        );
        selectedProtocol.debitorStatement = action.payload?.debitorStatement;
        selectedProtocol.isDebitorStatementSuccess = true;
        draft.ui.busy.isDebitorStatementLoading = false;
        break;
      }
      case COMMON_GET_DEBITOR_STATEMENT_ERROR: {
        const selectedProtocol = draft.protocols.find(
          (protocol) => protocol.id === action.payload.protocolId,
        );
        selectedProtocol.debitorStatement = {};
        selectedProtocol.isDebitorStatementSuccess = false;
        draft.ui.busy.isDebitorStatementLoading = false;
        break;
      }
      default:
        return state;
    }
  });
