/** @jsxImportSource @emotion/react */
import { useCallback, useMemo, memo, useLayoutEffect, useRef } from 'react';
import classNames from 'classnames';
import Fade from 'react-reveal/Fade';
import { Draggable } from 'react-beautiful-dnd';
import { any } from 'ramda';

import { colors } from '../../../common/theme/colors';
import { variables } from '../../../common/theme/variables';
import {
  getStateStyle,
  getStateSymbol,
  stateIndexes,
  getStateFontStyle,
  getStateIndex,
  getCopiedStyle,
} from '../../common/Functions';
import { getLocalName } from './Functions';
import { ReorderSmallIcon } from '../../common/ReorderSmallIcon';
import { Icon } from '../../common/Icon';

const styles = {
  container: {
    display: 'grid',

    content: {
      display: 'grid',
      minHeight: '3em',
      gridTemplateColumns: '5px 1em auto min-content min-content',
      gridGap: '8px',
      color: `${colors.greyTextElementAccordion}`,
      '&.is-selected': {
        color: `${colors.whiteSmoke}`,
      },
      '&.is-children': {
        backgroundColor: `#383838`,
        paddingLeft: '20px',
        borderBottom: `1px ${colors.greyBorderAcceptanceProtocol} solid`,
      },
      '&.has-no-children': {
        borderBottom: `1px ${colors.greyBorderAcceptanceProtocol} solid`,
      },
      borderLeft: 'none',

      state: {
        transition: '0.5s background-color',
      },

      symbol: {
        display: 'grid',
        alignItems: 'center',
        color: `${colors.whiteSmoke}`,
      },

      button: {
        padding: '4px 6px',

        textAlign: 'left',
        backgroundColor: `${colors.dark}`,
        color: `${colors.greyTextElementAccordion}`,
        textOverflow: 'ellipsis',
        overflow: 'hidden',

        '&.is-selected': {
          color: `${colors.whiteSmoke}`,
        },

        '&.is-children': {
          backgroundColor: '#383838',
        },

        [variables.breakpoints.xl]: {
          paddingLeft: '16px',
        },
      },

      icons: {
        justifySelf: 'right',
        alignSelf: 'center',
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        columnGap: '2px',
      },

      icon: {
        backgroundColor: 'transparent',
        color: 'white',
        width: '24px',
        height: '24px',
        display: 'flex',
        placeContent: 'center',
        alignItems: 'center',
        '& svg': {
          width: '100%',
          color: colors.greyTextElementAccordion,
        },
      },

      left: {
        justifySelf: 'right',
        alignSelf: 'center',
        width: '0',
        height: '0',
        borderTop: `16px solid transparent`,
        borderBottom: `16px solid transparent`,
        borderRight: `16px solid transparent`,
      },

      selected: {
        borderRight: `16px solid white`,
      },
    },
  },
  reordering: {
    display: 'grid',
    gridTemplateColumns: '5px 1em auto min-content',
    minHeight: '3em',
    gridGap: '5px',
    color: `${colors.greyTextElementAccordion}`,

    alignItems: 'center',
    height: '100%',
    paddingRight: '20px',

    container: (isDragging) => ({
      backgroundColor: `${colors.dark}`,
      border: '2px solid transparent',
      ...(isDragging && { border: `2px dashed ${colors.brand}` }),
    }),

    content: {
      padding: '0.25em',
      userSelect: 'none',
      textAlign: 'left',
      backgroundColor: `${colors.dark}`,
      color: `${colors.greyTextElementAccordion}`,
      textOverflow: 'ellipsis',
      overflow: 'hidden',

      [variables.breakpoints.xl]: {
        paddingLeft: '1em',
      },
    },

    reorderButton: {
      justifySelf: 'center',
      alignSelf: 'center',
      backgroundColor: 'transparent',
      color: 'white',
      width: '32px',
      height: '32px',
    },
  },
};

const AccordionElement = (props) => {
  const {
    isWithRemReference,
    componentId,
    id,
    index,
    template,
    input,
    language,
    isSelected,
    getLocalized,
    isReordering,
    onSelectElement,
    isChildren
  } = props;

  const elementRef = useRef(null);

  const handleSelectElement = useCallback(() => {
    elementRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    onSelectElement(template.id);
  }, [template.id, onSelectElement]);

  useLayoutEffect(() => {
    if (isSelected) {
      elementRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [isSelected]);

  const stateIndex = useMemo(() => !!input && getStateIndex(input.state), [input]);

  const hasCost = useMemo(
    () =>
      !!input &&
      !!input.attributesSelected &&
      any(
        (x) =>
          !!x.cost &&
          ((!!x.cost.value && (x.cost.value > 0 || x.cost.value !== '')) ||
            (!!x.cost.id && x.cost.id > 0)),
      )(input.attributesSelected),
    [input],
  );

  const hasImage = useMemo(() => !!input && !!input.images && !!input.images.length, [input]);

  const hasComment = useMemo(() => !!input && !!input.comment, [input]);

  let content;
  if (isReordering) {
    content = (
      <Draggable
        type="element"
        draggableId={`component-${componentId}-element-${id}`}
        index={index}
        isDragDisabled={!isReordering}
      >
        {(provided, snapshot) => {
          const { style, ...otherDraggableProps } = provided.draggableProps;

          return (
            <div
              ref={provided.innerRef}
              {...otherDraggableProps}
              style={{ ...style, ...styles.reordering.container(snapshot.isDragging) }}
            >
              <div css={styles.reordering}>
                <div />
                <div />
                <div css={styles.reordering.content}>
                  {getLocalName(input) || getLocalized(template, language)}
                </div>
                <div css={styles.reordering.reorderButton} {...provided.dragHandleProps}>
                  <ReorderSmallIcon />
                </div>
              </div>
            </div>
          );
        }}
      </Draggable>
    );
  } else {
    content = (
      <div
        css={styles.container.content}
        className={classNames({
          'has-no-children': !template.children || !isWithRemReference,
          'is-children': isChildren,
        })}
      >
        <div
          css={styles.container.content.state}
          style={getCopiedStyle(input) || getStateStyle(stateIndex)}
        />
        <div css={styles.container.content.symbol}>
          <Fade when={stateIndex > 0 && stateIndex < stateIndexes.length}>
            {getStateSymbol(stateIndex)}
          </Fade>
        </div>
        <button
          css={styles.container.content.button}
          style={getStateFontStyle(stateIndex)}
          onClick={handleSelectElement}
          className={classNames({ 'is-selected': isSelected, 'is-children': isChildren })}
        >
          {getLocalName(input) || getLocalized(template, language)}
        </button>
        <div css={styles.container.content.icons}>
          {hasCost && (
            <div css={styles.container.content.icon}>
              <Icon icon="money-bill" size="1x" />
            </div>
          )}
          {hasImage && (
            <div css={styles.container.content.icon}>
              <Icon icon="image" size="1x" />
            </div>
          )}
          {hasComment && (
            <div css={styles.container.content.icon}>
              <Icon icon="comment" size="1x" />
            </div>
          )}
        </div>
        <div
          css={styles.container.content.left}
          style={isSelected ? styles.container.content.selected : null}
        />
      </div>
    );
  }

  return (
    <div key={template.id} css={styles.container} ref={elementRef}>
      {content}
    </div>
  );
};

export default memo(AccordionElement);
