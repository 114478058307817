/** @jsxImportSource @emotion/react */
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { join } from 'ramda';
import { useCallback, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { convertBase64ToFile } from '../../common/code/convertBase64ToFile';
import { colors } from '../../common/theme/colors';
import {
  getLocalizedName,
  getLocalizedNameForComponent,
  getLocalName,
} from "../acceptance-protocol/fill-step/Functions";
import Button from '../common/Button';
import FullScreenModal from '../common/FullScreenModal';
import { ImageSketcherColor } from './ImageSketcherColor';
import ImageSketcherCanvas from './ImageSketcherCanvas';
import Spinner from './Spinner';

const styles = {
  container: {
    padding: '16px 24px',
    gridGap: '16px',
    display: 'grid',
    height: '100%',
    gridTemplateRows: 'auto min-content',
  },
  content: {
    display: 'grid',
    justifyContent: 'center',
    alignContent: 'center',
    overflow: 'auto',

    sketch: {
      background: 'white',
      display: 'grid',
      alignItems: 'center',
      '& > canvas': {
        maxWidth: '100%',
      },
    },
  },
  buttons: {
    display: 'grid',
    gridGap: '0 16px',
    gridTemplateColumns: '3fr 1fr 1fr 1fr',
  },
  colors: {
    display: 'grid',
    gridGap: '16px',
    gridAutoFlow: 'column',
    gridAutoColumns: 'min-content',
    alignItems: 'center',
  },
};

const ImageSketcherModal = (props) => {
  const { isOpen, image, component, element, onCancel, onSave, language } = props;

  const [isBusy, setIsBusy] = useState(false);
  const [color, setColor] = useState(colors.brand);
  const [handleClear, setHandleClear] = useState(() => {});
  const [handleSave, setHandleSave] = useState(() => {});

  const handleSaveClick = useCallback(() => {
    setIsBusy(true);
    const { dataURL, id } = handleSave();

    try {
      const blob = convertBase64ToFile(dataURL);

      onSave({ id, data: blob, filename: image.filename, reference: uuid() });
    } catch (error) {
      document.writeln('Error on saving image, page reloading...');
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
    setIsBusy(false);
  }, [image, onSave, handleSave]);

  const handleClearClick = useCallback(() => {
    handleClear();
  }, [handleClear]);

  return (
    <FullScreenModal isOpen={isOpen}>
      <Spinner show={isBusy}>
        <div css={styles.container}>
          <div css={styles.content}>
            {!!image && (
              <ImageSketcherCanvas
                onSave={(save) => {
                  setHandleSave(() => save);
                }}
                onClear={(clear) => {
                  setHandleClear(() => clear);
                }}
                pictureBase64={image.data}
                css={styles.content.sketch}
                lineColor={color}
                lineWidth={2}
                imageName={join(' - ', [
                  getLocalizedNameForComponent(component.input, component.template, language),
                  (getLocalName(element.input) || getLocalizedName(element.template, language)),
                ])}
                language={language}
                width={!!image && !!image.size ? image.size.width : 800}
                height={!!image && !!image.size ? image.size.height : 600}
              />
            )}
          </div>
          <div css={styles.buttons}>
            <div css={styles.colors}>
              <ImageSketcherColor color={colors.brand} onChange={setColor} />
              <ImageSketcherColor color="yellow" onChange={setColor} />
              <ImageSketcherColor color="red" onChange={setColor} />
            </div>
            <Button secondary onClick={onCancel} data-testid={'cancel-btn'}>
              <Trans>BUTTONS.CANCEL</Trans>
            </Button>
            <Button secondary onClick={handleClearClick}>
              <Trans>BUTTONS.CLEAR-CONTENT</Trans>
            </Button>
            <Button
              secondary
              onClick={handleSaveClick}
              disabled={!handleSave}
              data-testid={'save-btn'}
            >
              <Trans>BUTTONS.SAVE</Trans>
            </Button>
          </div>
        </div>
      </Spinner>
    </FullScreenModal>
  );
};

ImageSketcherModal.propTypes = {
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};

ImageSketcherModal.defaultProps = {
  isOpen: false,
};

export default ImageSketcherModal;
