/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';
import Creatable from 'react-select/creatable';
import { variables } from '../../common/theme/variables';
import { isMobileOnly } from 'react-device-detect';

const styles = {
  desktop: {
    display: 'none',
    [variables.breakpoints.xl]: {
      display: 'block',
    },
    [variables.breakpoints.lg]: {
      display: 'block',
    },
  },
  mobile: {
    display: 'grid',
    gridColumn: '1/3',
    '& > select': {
      height: '2.3em',
    },
    [variables.breakpoints.xl]: {
      display: 'none',
    },
  },
};

const creatableStyles = {
  menuPortal: (base) => {
    const { zIndex, ...rest } = base; // remove zIndex from base by destructuring
    return { ...rest, zIndex: 9999 };
  },
  menu: (base) => ({
    ...base,
    // kill the gap
    marginTop: 1,
  }),
};

const Dropdown = (props) => {
  const { 
    options,
    name,
    defaultValue,
    optionValue,
    selected,
    setFieldTouched,
    setFieldValue,
    controlledInput,
    onControlledInputChange  
  } = props;

  const onChange = useCallback(
    (value) => {
      if (!!value) {
        setFieldValue(name, value.value);
      } else {
        setFieldValue(name, null);
      }
    },
    [name, setFieldValue],
  );

  const onBlur = useCallback(() => {
    setFieldTouched(name, true);
  }, [name, setFieldTouched]);

  return (
    <div className={props.className}>
      <div css={isMobileOnly ? styles.mobile : styles.desktop}>
        <Creatable
          styles={creatableStyles}
          onChange={onChange}
          onBlur={onBlur}
          isClearable={true}
          optionValue={optionValue}
          options={options}
          placeholder={defaultValue.label}
          formatCreateLabel={(value) => value}
          value={selected}
          {...(onControlledInputChange && {
            inputValue: controlledInput,
            onInputChange: (value, { action }) => onControlledInputChange(name, value, action),
          })}
        />
      </div>
    </div>
  );
};

export default Dropdown;
