/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';
import { i18n } from "../../../common/i18n-loader";
import Spinner from "../../common/Spinner";

const styles = {
  overflow: {
    overflowX: 'auto'
  },
  wrapper: {
    margin: 0,
    padding: 0,
    boxSizing: 'border-box',
    justifyContent: 'center',
    fontFamily: 'Arial, sans-serif',
    width: '100%',
    maxWidth: '100%',
    overflowX: 'auto',
  },
  tableContainer: {
    width: '100%',
    maxWidth: '100%',
    display: 'block',
    overflowX: 'auto',
  },
  listView: {
    display: 'none',
    '@media screen and (max-width: 768px)': {
      display: 'block',
    },
  },
  listItem: {
    padding: '12px',
    marginBottom: '10px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    background: '#f9f9f9',
  },
  listItemField: {
    marginBottom: '8px',
  },
  desktopView: {
    display: 'block',
    width: '100%',
    borderCollapse: 'collapse',
    tableLayout: 'auto',
    '@media screen and (max-width: 768px)': {
      display: 'none',
    },
  },
  tableHeader: {
    backgroundColor: '#aaaaaa',
    border: '1px solid #ddd',
    padding: '12px',
    fontWeight: 'bold',
    color: '#333',
    verticalAlign: 'middle',
  },
  tableCell: {
    padding: '5px',
    border: '1px solid #ddd',
    backgroundColor: '#fff',
    color: '#555',
    verticalAlign: 'middle',
    textAlign: 'center',
  },
};

export default class TableComponent extends Component {
  render() {
    const {
      isLoading,
      isSuccess,
      columns = [],
      data = []
    } = this.props;
    
    if (!isLoading) {
  if (!isSuccess) {
    return <>{i18n._('ERRORS.API.FETCH-ERROR')}</>;
  } else if (data?.length === 0) {
    return <>{i18n._('ERRORS.API.NOT-FOUND')}</>;
  }
}

    return (
      <div css={styles.overflow}>
        <Spinner show={isLoading}>
          <div css={styles.wrapper}>
            <div css={styles.tableContainer}>
                {/* Mobile View: List Layout */}
                <div css={styles.listView}>
                  {data?.map((row, index) => (
                    <div key={`${row.id}-${index}`} css={styles.listItem}>
                      {!!columns && columns.map((col) => (
                        <div key={`${row.id}-${col.accessor}`} css={styles.listItemField}>
                          <strong>{col.title}: </strong> {row[col.accessor]}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
    
                {/* Desktop View: Table Layout */}
                <table css={styles.desktopView}>
                  <colgroup>
                    <col style={{ minWidth: '150px' }} />
                  </colgroup>
                  <thead>
                  <tr key={'table-header'}>
                    {columns?.map((col) => (
                      <th key={col.accessor} css={styles.tableHeader}>
                        {col.title}
                      </th>
                    ))}
                  </tr>
                  </thead>
                  <tbody>
                  {
                   data?.map((row, index) => (
                      <tr
                        key={`${row.id}-${index}`}
                      >
                        {columns.map((col) => (
                          <td key={`${row.id}-${col.accessor}`} css={styles.tableCell}>
                            {row[col.accessor]}
                          </td>
                        ))}
                      </tr>
                    ))
                  }
                  </tbody>
                </table>
            </div>
          </div>
        </Spinner>
      </div>
    );
  }
}
