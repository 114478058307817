/** @jsxImportSource @emotion/react */
import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import { object, string } from 'yup';
import { useCallback, useMemo, useState } from 'react';

import { i18n } from '../../common/i18n-loader';

import { variables } from '../../common/theme/variables';
import Button from '../common/Button';
import Input from '../common/Input';
import Modal from '../common/Modal';
import Spinner from '../common/Spinner';
import { fieldRow } from './form';
import DropdownCreatable from '../common/DropdownCreatable';
import { map } from 'ramda';
import { isTablet, isBrowser } from 'react-device-detect';
import { colors } from '../../common/theme/colors';
import { LOGIN_WORKFLOW_EXPIRED_TOKEN } from './redux/loginWorkflowConstants';

const styles = {
  body: {
    display: 'grid',
    gridGap: '0em',
    gridTemplateRows: 'auto auto auto',
  },
  fieldRow: {
    ...fieldRow,
    gridTemplateColumns: '0.3fr 0.8fr',
    gridTemplateRows: '0.3fr 0.3fr 0.3fr',
    gridGap: '0em 1em',

    [variables.breakpoints.md]: {
      gridTemplateRows: '0.5fr 0.5fr 0.5fr',
      gridGap: '0em 1em',
    },
  },
  buttonsContainer: {
    display: 'grid',
    gridTemplateColumns: '0 1fr',

    [variables.breakpoints.md]: {
      gridTemplateColumns: '0.40fr 1fr',
    },
  },
  buttonsContainerSso: {
    display: 'grid',
    gridTemplateColumns: '0 1fr',

    button: {
      backgroundColor: colors.greyExternalLink,
      position: 'relative',
      img: {
        position: 'absolute',
        left: '1em',
        top: '.5em',
      },
    },

    [variables.breakpoints.md]: {
      gridTemplateColumns: '0.40fr 1fr',
    },
  },
  split: {
    margin: '1em',
    display: 'grid',
    textAlign: 'center',
  },
  buttons: {
    display: 'grid',
    gridGap: '1em',

    gridAutoFlow: 'row',

    [variables.breakpoints.md]: {
      gridAutoFlow: 'column',
    },
  },
  top: {
    buttons: {
      display: 'grid',
      justifyContent: 'right',
      alignContent: 'center',
      gridAutoFlow: 'column',
      gridGap: '1em',

      button: {
        backgroundColor: 'transparent',
        color: 'white',
        width: '32px',
        height: '32px',
      },
    },
  },
};

const LoginModal = (props) => {
  const { isOpen, onClose, onLogin, isBusy, loginInfo, client, loginWorkflow } = props;

  const [clientName, setClientName] = useState('');

  const validationSchema = object().shape({
    client: string().required(i18n._('VALIDATIONS.REQUIRED.CLIENT')).nullable(),
    username: string().required(i18n._('VALIDATIONS.REQUIRED.USERNAME')).nullable(),
    password: string().required(i18n._('VALIDATIONS.REQUIRED.PASSWORD')),
  });

  let reset;
  function handleOnOpen() {
    if (!!reset) {
      reset();
    }
  }

  const customersInfo = useMemo(() => {
    return map((t) => ({ value: t.name, label: t.name }), loginInfo?.customers || []);
  }, [loginInfo]);

  const getCustomersInfo = useCallback(() => {
    if (!!loginInfo && !!loginInfo.customers) {
      return map((t) => ({ value: t.name, label: t.name }), loginInfo.customers);
    }
  }, [loginInfo]);

  const getLastCustomer = useCallback(
    (client) => {
      if (client) {
        return client;
      }

      if (!!loginInfo && !!loginInfo.customers && !!loginInfo.lastClient) {
        return loginInfo.lastClient;
      }
      return '';
    },
    [loginInfo],
  );

  const getLastUserName = useCallback(() => {
    if (!!loginInfo && !!loginInfo.customers && !!loginInfo.lastUsername) {
      return loginInfo.lastUsername;
    }
    return '';
  }, [loginInfo]);

  const getCurrentClient = useCallback(
    (client) => {
      if (client) {
        const item = getCustomersInfo().filter((c) => c.value === client)[0];

        if (item) {
          return item;
        }
      }

      return {
        value: -1,
        label: i18n._('DROPDOWN-TEMPLATE.DEFAULT-VALUE'),
      };
    },
    [getCustomersInfo],
  );
  
  const onControlledInputChange = (name, value, action) => {
    if (action === 'input-change') {
      setClientName(value?.toLowerCase());
    }
  }

  return (
    <Modal
      title={i18n._('LOGIN-MODAL.TEXTS.TITLE')}
      isOpen={isOpen}
      onOpen={handleOnOpen}
      onClose={onClose}
      width="50vw"
      showButton="true"
      zIndex={99999}
      blocker={loginWorkflow === LOGIN_WORKFLOW_EXPIRED_TOKEN}
    >
      <Spinner show={isBusy}>
        <Formik
          initialValues={{
            username: getLastUserName(),
            password: '',
            client: getLastCustomer(client),
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => onLogin(values.username, values.password, values.client)}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            setFieldTouched,
          }) => {
            reset = handleReset;
            return (
              <form
                onSubmit={handleSubmit}
                onReset={handleReset}
                css={styles.body}
                autoComplete="off"
              >
                <div css={styles.fieldRow}>
                  <label>
                    <Trans>LABELS.SERVER</Trans>
                  </label>

                  {values.client ? (
                    <Input
                      placeholder={i18n._('LABELS.SERVER')}
                      type="text"
                      name="client"
                      onChange={(event) => {
                        event.target.value = event.target.value?.toLowerCase();
                        handleChange(event)
                      }}
                      onBlur={handleBlur}
                      value={values.client}
                      className={classNames({ 'has-error': touched.client && errors.client })}
                      disabled={loginWorkflow === LOGIN_WORKFLOW_EXPIRED_TOKEN}
                    />
                  ) : customersInfo.length > 0 && (isTablet || isBrowser) ? (
                    <DropdownCreatable
                      name="client"
                      defaultValue={getCurrentClient(values.client)}
                      options={getCustomersInfo()}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      value={values.client}
                      className={classNames({ 'has-error': touched.client && errors.client })}
                      controlledInput={clientName}
                      onControlledInputChange={onControlledInputChange}
                    />
                  ) : (
                    <Input
                      placeholder={i18n._('LABELS.SERVER')}
                      type="text"
                      name="client"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.client}
                      className={classNames({ 'has-error': touched.client && errors.client })}
                    />
                  )}
                  <Fade right when={!!touched.client && !!errors.client}>
                    <span>{errors.client}</span>
                  </Fade>
                </div>
                <div css={styles.fieldRow}>
                  <label>
                    <Trans>LABELS.LOGIN</Trans>
                  </label>

                  <Input
                    placeholder={i18n._('LABELS.LOGIN')}
                    type="text"
                    name="username"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.username}
                    className={classNames({ 'has-error': touched.username && errors.username })}
                    disabled={loginWorkflow === LOGIN_WORKFLOW_EXPIRED_TOKEN}
                  />

                  <Fade right when={!!touched.username && !!errors.username}>
                    <span>{errors.username}</span>
                  </Fade>
                </div>
                <div css={styles.fieldRow}>
                  <label>
                    <Trans>LABELS.PASSWORD</Trans>
                  </label>
                  <Input
                    placeholder={i18n._('LABELS.PASSWORD')}
                    type="password"
                    name="password"
                    autoComplete="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    className={classNames({ 'has-error': touched.password && errors.password })}
                  />
                  <Fade right when={!!touched.password && !!errors.password}>
                    <span>{errors.password}</span>
                  </Fade>
                </div>
                <div css={styles.buttonsContainer}>
                  <div />
                  <div css={styles.buttons}>
                    <Button secondary type="reset" onClick={onClose}>
                      {loginWorkflow === LOGIN_WORKFLOW_EXPIRED_TOKEN ? (
                        <Trans>COMMON.BUTTONS.LOGOUT</Trans>
                      ) : (
                        <Trans>BUTTONS.OFFLINE</Trans>
                      )}
                    </Button>
                    <Button default type="submit">
                      <Trans>BUTTONS.LOGIN</Trans>
                    </Button>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </Spinner>
    </Modal>
  );
};

LoginModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onLogin: PropTypes.func,
  isBusy: PropTypes.bool,
  loginInfo: PropTypes.any,
  isSsoActive: PropTypes.bool,
  client: PropTypes.string,
  loginWorkflow: PropTypes.number,
};

LoginModal.defaultProps = {
  isOpen: false,
};

export default LoginModal;
