/** @jsxImportSource @emotion/react */
import { useCallback, useRef, useEffect, memo, forwardRef } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import { Droppable } from 'react-beautiful-dnd';
import {
  map,
  addIndex,
  filter,
  findIndex,
  reduce,
  remove,
  insert,
  intersection,
  pluck,
} from 'ramda';

import AccordionHeader from './AccordionHeader';
import AccordionElement from './AccordionElement';

const styles = {
  container: {
    maxHeight: '0',
    transition: '0.2s max-height',
    overflow: 'hidden',

    '&.is-open': {
      display: 'grid',
      // 100% or others do not trigger animation
      // 100vh is not right
      maxHeight: '5000px',
    },
  },
};

function isSelectedElement(selectedComponentId, selectedElementId, componentId, elementId) {
  return (
    !!selectedComponentId &&
    !!selectedElementId &&
    selectedComponentId === componentId &&
    selectedElementId === elementId
  );
}

const Accordion = forwardRef((props, ref) => {
  const {
    isWithRemReference,
    title,
    id,
    index,
    type,
    elementTemplates,
    indexedElementInputs,
    language,
    onSelectElement,
    selectedComponentId,
    selectedElementId,
    getLocalized,
    elementsOrder,
    isOpen,
    isReordering,
    accordionReorderingId,
    onAddAccordion,
    onOpen,
    isDuplicable,
    onDuplicate,
    onCopy,
    onRemove,
    onAccordionReorder,
    onEditComponent,
  } = props;

  const containerRef = useRef(null);
  const elementRef = useRef(null);

  const handleOpen = useCallback(() => {
    onOpen(id);
  }, [id, onOpen]);

  const handleAccordionReorder = useCallback(() => {
    const updated = !accordionReorderingId;

    onAccordionReorder(!!updated ? id : null);
  }, [id, accordionReorderingId, onAccordionReorder]);

  const handleSelectElement = useCallback(
    (elementId) => onSelectElement({ type, elementId: elementId, componentId: id }),
    [id, type, onSelectElement],
  );

  const handleOnDuplicate = useCallback(() => onDuplicate(id), [id, onDuplicate]);

  const handleOnCopy = useCallback(() => {
    onCopy(id, title);
  }, [id, title, onCopy]);

  const handleOnRemove = useCallback(() => {
    onRemove(id);
  }, [id, onRemove]);

  const handleEditComponent = useCallback(() => onEditComponent(id), [id, onEditComponent]);

  const elementTemplatesOrdered = !!elementsOrder
    ? addIndex(reduce)(
        (acc, val, idx) => {
          const index = findIndex((x) => x.id === val, acc);
          const item = acc[index];
          return insert(idx, item, remove(index, 1, acc));
        },
        elementTemplates,
        intersection(
          pluck('id', elementTemplates),
          filter((e) => !!e, elementsOrder),
        ),
      )
    : elementTemplates;

  useEffect(() => {
    if (!!elementRef.current) {
      onAddAccordion(id, title, elementRef);
    }
  }, [id, title, elementRef, onAddAccordion]);

  return (
    <Draggable
      type="component"
      draggableId={id}
      index={index}
      isDragDisabled={!isReordering || (isReordering && type.toLowerCase() === 'basedata')}
    >
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <div ref={elementRef}>
            <AccordionHeader
              id={id}
              isReordering={isReordering}
              isOpen={isOpen}
              title={title}
              type={type}
              accordionReorderingId={accordionReorderingId}
              onOpen={handleOpen}
              onAccordionReorder={handleAccordionReorder}
              dragHandleProps={provided.dragHandleProps}
              isDuplicable={isDuplicable}
              onDuplicate={handleOnDuplicate}
              onRemove={handleOnRemove}
              onCopy={handleOnCopy}
              onEditComponent={handleEditComponent}
            />
            {!isReordering && (!accordionReorderingId || accordionReorderingId === id) && (
              <Droppable droppableId={`component-${id}`} type="element">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <div
                      css={styles.container}
                      ref={containerRef}
                      className={classNames({ 'is-open': isOpen })}
                    >
                      {addIndex(map)((template, index) => {
                        const input = indexedElementInputs && indexedElementInputs[template.id];

                        const isSelected = isSelectedElement(
                          selectedComponentId,
                          selectedElementId,
                          id,
                          template.id
                        );

                        let isChildrenSelected = false;

                        if(!isSelected && typeof selectedElementId === 'string' && selectedElementId.includes('/')) {
                          isChildrenSelected = template.children?.some((child) => child.id === selectedElementId);
                        }

                        return (
                            <>
                              <AccordionElement
                                  isWithRemReference={isWithRemReference}
                                  componentId={id}
                                  id={template.id}
                                  index={index}
                                  key={template.id}
                                  type={type}
                                  template={template}
                                  input={input}
                                  language={language}
                                  isSelected={isSelected}
                                  isReordering={accordionReorderingId === id}
                                  getLocalized={getLocalized}
                                  onSelectElement={handleSelectElement}
                              />
                              {
                                isWithRemReference && template.children?.map((child) => {
                                  return (
                                    <AccordionElement
                                      isWithRemReference={isWithRemReference}
                                      componentId={template.id}
                                      id={child.id}
                                      index={child.id}
                                      key={child.id}
                                      type={type}
                                      template={child}
                                      input={input}
                                      language={language}
                                      isSelected={isChildrenSelected}
                                      isReordering={accordionReorderingId === id}
                                      getLocalized={getLocalized}
                                      onSelectElement={handleSelectElement}
                                      isChildren={true}
                                    />
                                  )
                                })
                              }
                            </>
                        );
                      }, elementTemplatesOrdered)}
                      {provided.placeholder}
                    </div>
                  </div>
                )}
              </Droppable>
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
});

export default memo(Accordion);
